@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --background-light: 210, 20%, 98%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 203 98% 38%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 203 98% 38%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.sidebar-link {
	@apply flex items-center gap-2 text-zinc-600 py-2 px-4 rounded-md text-sm hover:bg-white hover:text-primary hover:shadow-sm;
}

.sidebar-link.active {
	@apply text-white bg-primary hover:text-white hover:bg-primary;
}

.align-centre {
  text-align: center;
}

/* .navbarStyle {
  padding: .5rem 1rem !important
} */

.text-gray-800 {
  color: #5a5c69 !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.navBorder {
  border: 0.4em groove #439CD3;
  /* margin: 2em; */
  }


  .courierBorder {
    border: 0.4em groove #439CD3;
    margin: 0.1em;
    }

  .DashedCourierBorder {
    
    border: 0.2em dashed #ccc;
    margin: 0.1em;
  }



    .dropzone {
      border: 2px dashed #ccc;
      padding: 1.2500em;
      text-align: center;
    }

    .inputborder {
      border: 0.3em solid #ccc;
      padding: 1.2500em;
    }

    .inputbordersm {
      border: 0.3em solid #ccc;
      height: 3.5em;
    }
    .dropzoneimage {
      border: 0.1em dashed #ccc;
      padding: 0.5em;
      margin: 0.5em;
    }

    .button-align {
      border: 0.2em dashed #aca9a9;
      padding: 0.6em;

      text-align: center;
    }
    
    .uploaded-image {
      max-width: 1.2500em;
      margin-top: 10px;
    }
    

    .uploaded-image-modal {
      max-width: 48em;
      margin-top: 10px;
    }

    .custom-input {
      height: 3.5em; /* Adjust the height value as needed */
  }

  .align-left {
    text-align: left;
  }

  .align-verticle {
    vertical-align: middle;
    height:auto;
  }

 


  .align-right {
    text-align: right;
  }


  input[type="radio"] {
    transform: scale(2); /* Increase the scale factor to adjust the size */
  }

  input[type="checkbox"] {
    transform: scale(2); /* Increase the scale factor to adjust the size */
  }

  .watermark-container {
    position: relative;
    /* width: 100%;
    height: 100%; */
  }


  .watermarkapproved {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    /* z-index: 0; */
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10em;
    transform: rotate(-45deg);
    color: rgba(1, 107, 42, 0.247);
    /* background-image: linear-gradient(to right top, rgba(0, 0, 0, 0.1) 50%, transparent 50%); */
  }
  

  .watermarkrejected {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    /* z-index: 0; */
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10em;
    transform: rotate(-45deg);
    color: rgba(20, 171, 182, 0.247);
    /* background-image: linear-gradient(to right top, rgba(0, 0, 0, 0.1) 50%, transparent 50%); */
  }

  
  .watermark {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    /* z-index: 0; */
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10em;
    transform: rotate(-45deg);
    color: rgba(0, 0, 0, 0.274);
    /* background-image: linear-gradient(to right top, rgba(0, 0, 0, 0.1) 50%, transparent 50%); */
  }
  
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .iframe-container {
    position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 75%;
  }

  .navbar {
    background-color: #439CD3;
    height: 5em;
    display: flex;
    color: #f5f5f5;
    /* justify-content: flex-start; */
    align-items: center;
  }
  
  .bg-color-font{
    color: #ffffff !important;
  
  }
  
  .jumbotron .d-flex .align-items-center{
    width: 100% !important;
    height: 100% !important;
  }
  
  .navbar-navl .nav-linkl{
    color: #ffffff;
    float: left;
  display: block;
  }
  
  .menu-bars {
    margin-left: 2em;
    font-size: 2em;
    background: none;
  }
  .menu-bars1 {
    margin-left: 2em;
    font-size: 2em;
    background: none;
  }
  
  hr.divider {
    margin: 0em;
    border-width: 0.063em;
  }
  
  
  .cl {
    background-color: #439CD3;
  }
  
  .nav-menu {
    background-color:#439CD3;
    width: 17.625em;
    height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 10;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5em 0em 0.5em 1em;
    list-style: none;
    height: 3.75em;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 1.125em;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1em;
    border-radius: 0.25em;
  }
  
  .nav-text a:hover {
    background-color: #030303;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    /* background-color:#030303; */
    width: 100%;
    height: 2em;
    font-size: 2em;
    margin-left: 6em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .auth-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .auth-inner {
    width: 28.125em;
    margin: auto;
    background: #ffffff;
    box-shadow: 0em 0.875em 5em rgba(34, 35, 58, 0.2);
    padding: 2.5em 3.438em 2.813em 3.438em;
    border-radius: 0.938em;
    /* transition: all .3s; */
    position: absolute;
    top: 50%;
    left: 100%;
  }
  
  .navbar-navx{
    left: 50%;
    top:1%;
  }
  
  .logo_transparent{
    right: 4em;
  bottom: 4em;
  position: fixed;
  /* width: 30em; */
  }
  
  .logo_transparentn{
    /* right: 4em;
  bottom: 4em;
  position: fixed; */
  left:50%;
  top:1%;
  width: 12em;
  }
  
  
  .auth-wrapperUs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .auth-innerUs {
    width: auto;
    margin: auto;
    background: #ffffff;
    box-shadow: 0em 0.875em 5em rgba(34, 35, 58, 0.2);
    padding: 2.5em 3.438em 2.813em 3.438em;
    border-radius: 0.938em;
    /* transition: all .3s;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
  }
  
  .auth-wrapperR {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .auth-innerR {
    width: 40.125em;
    margin: auto;
    background: #ffffff;
    box-shadow: 0em 0.875em 5em rgba(34, 35, 58, 0.2);
    padding: 2.5em 3.438em 2.813em 3.438em;
    border-radius: 0.938em;
    /* transition: all .3s;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
  }
  
  .forgot-password,
  .forgot-password a {
  text-align: right;
  font-size: 0.813em;
  padding-top: 0.625em;
  color: #7f7d7d;
  margin: 0;
  }
  
  .forgot-password a {
  color: #030303;
  }
  
  .auth-wrapper-register {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  }
  .auth-inner-register {
  width: 50em;
  margin: auto;
  background: #ffffff;
  box-shadow: 0em 0.875em 5em rgba(34, 35, 58, 0.2);
  padding: 2.5em 3.438em 2.813em 3.438em;
  border-radius: 0.938em;
  transition: all .3s;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  }
  
  .card {
  box-shadow: rgba(54, 54, 109, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset !important;
  }


  .customModal {
    background: #b2dbbf;
  
    width: 150%;
  }